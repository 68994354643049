import {
  LOGIN_PENDING,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  DISMISS_ERROR,
  CHECKING_CONFIRMATION_TOKEN,
  CONFIRMATION_TOKEN_VALID,
  CONFIRMATION_TOKEN_ERROR,
  SETTING_PASSWORD,
  PASSWORD_SET_FAILURE,
  PASSWORD_SET_SUCCESS,
  REQUESTING_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_SUCCESS,
  REQUEST_PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_SUCCESS,
  RESETTING_PASSWORD,
  VALIDATING_TOKEN,
  TOKEN_VALIDATION_FAILURE,
  TOKEN_VALIDATION_SUCCESS,
  DISMISS_SUCCESS,
  CREATING_USER,
  USER_CREATION_FAILURE,
  USER_CREATION_SUCCESS,
  GETTING_USER,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  UPDATING_USER,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  AUTHENTICATION_ERROR,
  UPDATING_SHOW_MOBILE_MODAL,
  SUCCESS_MESSAGE,
  UPDATE_ACCESS_TOKEN,
  RESENDING_SURVEY,
  RESENT_SURVEY_SUCCESS,
  RESENT_SURVEY_FAILURE,
  SET_SHOW_PULSE_CHECK_SURVEY
} from "./_types.actions";
import { navigateTo } from '../../_config/history.config';
import axios from "axios";

const loginPending = status => ({type: LOGIN_PENDING, status});
const loginFailure = error => ({type: LOGIN_FAILURE, error });
const loginSuccess = response => ({type: LOGIN_SUCCESS, response });

const confirmationPending = status => ({type: CHECKING_CONFIRMATION_TOKEN, status});
const confirmationValid = status => ({type: CONFIRMATION_TOKEN_VALID, status});
const confirmationError = error => ({type: CONFIRMATION_TOKEN_ERROR, error });

const settingPassword = status => ({type: SETTING_PASSWORD, status});
const passwordSetSuccess = status => ({type: PASSWORD_SET_SUCCESS, status});
const passwordSetFailure = error => ({type: PASSWORD_SET_FAILURE, error});

const requestingPasswordReset = status => ({type: REQUESTING_PASSWORD_RESET, status});
const requestPasswordResetSuccess = status => ({type: REQUEST_PASSWORD_RESET_SUCCESS, status});
const requestPasswordResetFailure = error => ({type: REQUEST_PASSWORD_RESET_FAILURE, error});

const resettingPassword= status => ({type: RESETTING_PASSWORD, status});
const passwordResetSuccess = status => ({type: PASSWORD_RESET_SUCCESS, status});
const passwordResetFailure = error => ({type: PASSWORD_RESET_FAILURE, error});

const resendingSurvey = status => ({type: RESENDING_SURVEY, status});
const resentSurveySuccess = status => ({type: RESENT_SURVEY_SUCCESS, status});
const resentSurveyFailure = error => ({type: RESENT_SURVEY_FAILURE, error});

const validatingToken = status => ({type: VALIDATING_TOKEN, status});
const tokenValidationSuccess = status => ({type: TOKEN_VALIDATION_SUCCESS, status});
const tokenValidationFailure = error => ({type: TOKEN_VALIDATION_FAILURE, error});

const creatingUser = status => ({type: CREATING_USER, status});
const userCreationSuccess = status => ({type: USER_CREATION_SUCCESS, status});
const userCreationFailure = error => ({type: USER_CREATION_FAILURE, error});

const gettingUser = status => ({type: GETTING_USER, status});
const userGetSuccess = response => ({type: USER_GET_SUCCESS, response});
const userGetFailure = error => ({type: USER_GET_FAILURE, error});

const updatingUser = status => ({type: UPDATING_USER, status});
const userUpdateFailure = error => ({type: USER_UPDATE_FAILURE, error});
const userUpdateSuccess = response => ({type: USER_UPDATE_SUCCESS, response});

export const setShowPulseCheckSurvey = value => ({type: SET_SHOW_PULSE_CHECK_SURVEY, value})

export const updatingShowMobileModal = (bool) => ({type: UPDATING_SHOW_MOBILE_MODAL, bool});


const defaultLocation = { pathname: '/', search: '', hash: '', state: {} };

export const authentication_error = error => ({type:AUTHENTICATION_ERROR, error});

export const success_message = status => ({type: SUCCESS_MESSAGE, status});

//TODO figure out how to persist sessions in case browser is accidentally closed
export function login(credentials, location=defaultLocation) {
  return (dispatch, getState)=> {
    if (getState().authentication.requestPending) {
      return;
    }
    dispatch(loginPending(true));

    axios.post(`${process.env.REACT_APP_API_URL}/auth/sign_in`,{uid: credentials.email, password: credentials.password})
      .then(function (response) {
        // handle success

        localStorage.setItem('currentSession',JSON.stringify({
          // TODO do not store user info in localStorage. reacquire it from rails
          user: response.data.data,
          email: response.headers.uid,
          accessToken: response.headers["access-token"],
          client: response.headers.client,
          expiry: response.headers.expiry
        }));
        // clear zendesk web widget before prefill
        // window.zE('webWidget', 'clear');

        // prefill zendesk web widget
        // window.zE('webWidget', 'prefill', {
        //   email: {
        //     value: response.headers.uid,
        //     readOnly: false
        //   },
        // });

        // console.log(response.data.data,
        //   shouldShowSurvey(response.data.data.last_survey_taken),
        //   response.data.data.burnout_role
        // );
        dispatch(loginSuccess(response));
        navigateTo(location);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          dispatch(loginFailure(error.response.data.errors));
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          dispatch(loginFailure(error.message));
        } else {
          // Something happened in setting up the request that triggered an Error
          dispatch(loginFailure(error.message));
        }
      })
      .finally(function () {
        dispatch(loginPending(false));
      });
  }
}

export const dismiss_error = () => ({type: DISMISS_ERROR});

export const dismiss_success = () => ({type: DISMISS_SUCCESS});

export function logout(error = null) {
  localStorage.clear();
  // window.zE('webWidget', 'clear');
  return {type: LOGOUT, error}
}

export function check_confirmation_token(token, email) {
  return (dispatch, getState)=> {
    if (getState().authentication.requestPending) {
      return;
    }
    dispatch(confirmationPending(true));

    axios.get(`${process.env.REACT_APP_API_URL}/api/users/by_token`,{params: {token: token, email: email}})
    .then(function (response) {
      console.log(response);
      dispatch(confirmationValid(response));
    })
    .catch(function (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('message'))
        {
          dispatch(confirmationError(error.response.data.message));
        } else {
          dispatch(confirmationError('Invalid Confirmation Link'));
        }
      } else {
        dispatch(confirmationError(error.message));
      }
      navigateTo('/login');
    })
    .finally(function () {
      dispatch(confirmationPending(false));
    });
  }
}

//http://localhost:3000/api/users/4?confirmation_token=5QDR-4U6ePs6RD86deCv&email=vic%2Bp2@boudolf.com&id=4&password=testpass00&password_confirmation=testpass00
export function set_password(user_id, email, token, password, password_confirmation, location = defaultLocation) {
  return (dispatch, getState)=> {
    if (getState().authentication.requestPending) {
      return;
    }
    dispatch(settingPassword(true));

    axios.put(`${process.env.REACT_APP_API_URL}/api/users/${user_id}`,
      {confirmation_token: token, email, password, password_confirmation})
    .then(function (/*response*/) {
      // handle success
      dispatch(passwordSetSuccess("Your account has been confirmed!\n\rYou may login now."));
      // pass along location info (such as search and hash) to login page
      navigateTo(
        `/login${location.search || ''}${location.hash || ''}`,
        true, // replace
        location.state // state
      );
    })
    .catch(function (error) {
      console.error(error.response);
      if (error.response) {
        // case where something is wrong about the passwords
        const errors = [];
        if (error.response.data && error.response.data.errors && error.response.data.errors.hasOwnProperty('password')) errors.push("Password " + error.response.data.errors.password[0]);
        if (error.response.data && error.response.data.errors && error.response.data.errors.hasOwnProperty('password_confirmation')) errors.push("Password Confirmation " + error.response.data.errors.password_confirmation[0]);
        dispatch(passwordSetFailure(errors));
      } else if (error.request) {
        dispatch(passwordSetFailure(error.message));
      } else {
        dispatch(passwordSetFailure(error.message));
      }
    })
    .finally(function () {
      dispatch(settingPassword(false));
    });
  }
}

export function update_user(user_id, credentials, data) {
  return dispatch => {
    dispatch(updatingUser(true));
    axios.put(
      `${process.env.REACT_APP_API_URL}/api/providers/${user_id}`,
      data,
      {
        headers: {
          uid: credentials.email,
          "access-token": credentials.accessToken,
          client: credentials.client
        }
      }
    )
    .then(function(response) {
      console.log("HANDLE SUCCESS");
      console.log(response.data);
      // update user info in local storage
      localStorage.setItem('currentSession',JSON.stringify({
        // TODO do not store user info in localStorage. reacquire it from rails
        user: response.data,
        email: response.headers.uid,
        accessToken: response.headers["access-token"],
        client: response.headers.client,
        expiry: response.headers.expiry
      }));
      dispatch(userUpdateSuccess(response));
      localStorage.setItem('currentSession',JSON.stringify({
        user: response.data,
        ...credentials
      }));
    })
    .catch(function (error) {
      console.log("HANDLE FAILURE")
      console.error(error.response)
      if (error.response) {
        dispatch(userUpdateFailure(error.response.data.errors));
      } else if (error.request) {
        dispatch(userUpdateFailure(error.message));
      } else {
        dispatch(userUpdateFailure(error.message));
      }
    })
  }
}

export function request_password_reset(email) {
  return (dispatch, getState)=> {
    if (getState().authentication.requestPending) {
      return;
    }
    dispatch(requestingPasswordReset(true));
    console.log("REQUESTING PASSWORD RESET PARAMS",email);

    axios.post(`${process.env.REACT_APP_API_URL}/auth/password`,{email,redirect_url: `${process.env.REACT_APP_BASE_URL}/reset-password`})
    .then(function (response) {
      console.log('REQUEST PASSWORD RESET SUCCESS!',response);
      dispatch(requestPasswordResetSuccess(response.data.message));
      navigateTo('/login');
    })
    .catch(function (error) {
      console.log("REQUEST PASSWORD RESET FAILURE!", error);
      if (error.response) {
        dispatch(requestPasswordResetFailure(error.response.data.errors));
      } else if (error.request) {
        dispatch(requestPasswordResetFailure(error.message));
      } else {
        dispatch(requestPasswordResetFailure(error.message));
      }
    })
    .finally(function () {
      dispatch(requestingPasswordReset(false));
    });
  }
}

export function resend_survey(email) {
  return dispatch => {
    dispatch(resendingSurvey(true));

    axios.put(`${process.env.REACT_APP_API_URL}/api/resend_survey`,{email})
      .then(function (response) {
        console.log('RESEND SURVEY SUCCESS!',response);
        dispatch(resentSurveySuccess(response.data.message));
        navigateTo('/login');
      })
      .catch(function (error) {
        console.log("RESEND SURVEY FAILURE", error.response.data.message);
        if (error.response) {
          dispatch(resentSurveyFailure(error.response.data.message));
        } else if (error.request) {
          dispatch(resentSurveyFailure(error.message));
        } else {
          dispatch(resentSurveyFailure(error.message));
        }
      })
      .finally(function () {
        dispatch(resendingSurvey(false));
      });
  }
}

export function validate_token(credentials) {
  return (dispatch, getState)=> {
    if (getState().authentication.requestPending) {
      return;
    }
    dispatch(validatingToken(true));
    console.log("validating token",credentials);

    axios.get(`${process.env.REACT_APP_API_URL}/auth/validate_token`,{
      headers: {
        uid: credentials.email,
        "access-token": credentials.accessToken,
        client: credentials.client
      }
    })
    .then(function (response) {
      console.log('TOKEN VALIDATION SUCCESS!',response);
      dispatch(tokenValidationSuccess(response));
    })
    .catch(function (error) {
      console.log("TOKEN VALIDATION FAILURE!");
      if (error.response) {
        console.log(error.response);
        dispatch(tokenValidationFailure('Password Reset Link has expired'));
        // dispatch(tokenValidationFailure(error.response.data.errors));
      } else if (error.request) {
        dispatch(tokenValidationFailure(error.message));
      } else {
        dispatch(tokenValidationFailure(error.message));
      }
      navigateTo('/login');
    })
    .finally(function () {
      dispatch(validatingToken(false));
    });
  }
}

export function reset_password(credentials, password, password_confirmation) {
  return (dispatch, getState)=> {
    if (getState().authentication.requestPending) {
      return;
    }
    dispatch(resettingPassword(true));

    axios.put(`${process.env.REACT_APP_API_URL}/auth/password`,{
      email: credentials.email,
      password,
      password_confirmation
    },{
      headers: {
        uid: credentials.email,
        "access-token": credentials.accessToken,
        client: credentials.client
      }
    })
    .then(function (response) {
      // console.log('PASSWORD RESET SUCCESS!',response);
      dispatch(passwordResetSuccess(response.data.message));
      navigateTo('/login');
    })
    .catch(function (error) {
      console.log("PASSWORD RESET FAILURE!", error.response);
      if (error.response) {
        const errors = [];
        if (error.response.data && error.response.data.errors && error.response.data.errors.hasOwnProperty('password')) errors.push("Password " + error.response.data.errors.password[0]);
        if (error.response.data && error.response.data.errors && error.response.data.errors.hasOwnProperty('password_confirmation')) errors.push("Password Confirmation " + error.response.data.errors.password_confirmation[0]);
        dispatch(passwordResetFailure(errors));
      } else if (error.request) {
        dispatch(passwordResetFailure(error.message));
      } else {
        dispatch(passwordResetFailure(error.message));
      }
    })
    .finally(function () {
      dispatch(resettingPassword(false));
    });
  }
}

/**
 example body for provider creation
 {
  "enabled": true,
  "practice_ids": [40],
  "is_provider": true,
  "ce_eligible": true,
  "provider_title_id": 1,
  "first_name": "Test",
  "last_name": "Youngblood",
  "email": "jyb1337+test@gmail.com",
  "burnout_role": "physician",
  "dob_string": "01/01/1990",
  "gender": "M",
  "burnout_location": "",
  "burnout_specialty": ""
}
 */
export function create_user(first_name, last_name, email, specialty, gender, dob, license_type, organization, practice_id, block_id=null, years_in_profession=null) {
  return (dispatch, getState)=> {
    if (getState().authentication.requestPending) {
      return;
    }
    dispatch(creatingUser(true));

    axios.post(`${process.env.REACT_APP_API_URL}/api/create_burnout_user`, {
      "enabled": true,
      "practice_ids": [practice_id],
      "is_provider": true,
      "ce_eligible": true,
      "provider_title": license_type,
      first_name,
      last_name,
      "email": email,
      "burnout_role": "physician",
      "dob_string": dob,
      "years_in_profession": years_in_profession,
      gender,
      "burnout_location": organization,
      "burnout_specialty": specialty,
      block_id
    },{
      headers: {
        // uid: credentials.email,
        // "access-token": credentials.accessToken,
        // client: credentials.client
      }
    })
    .then(function (response) {
      console.log('USER CREATION SUCCESS!',response);
      dispatch(userCreationSuccess(`A confirmation email has been sent to ${email} with a link to set your initial password.`));
      const {confirmation_token} = response.data;
      navigateTo(`/confirm-password/${confirmation_token}/${email.toLowerCase()}`);
    })
    .catch(function (error) {
      console.error("USER CREATION FAILURE!", error.response);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data.errors.email) {
          dispatch(userCreationFailure("An account has already been setup with that email"));
          navigateTo(`/login?email=${email}`);
        } else {
          console.log(error.response);
          dispatch(userCreationFailure(error.response.data.errors))
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        dispatch(userCreationFailure(error.message));
      } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(userCreationFailure(error.message));
      }
    })
    .finally(function () {
      dispatch(creatingUser(false));
    });
  }
}

export function create_user_with_code(
  first_name,
  last_name,
  email,
  specialty,
  gender,
  dob,
  license_type,
  department,
  years_in_profession,
  code) {
  return (dispatch, getState)=> {
    if (getState().authentication.requestPending) {
      return;
    }
    dispatch(creatingUser(true));

    axios.post(`${process.env.REACT_APP_API_URL}/api/create_burnout_user_with_code`, {
      "provider_title": license_type,
      first_name,
      last_name,
      "email": email,
      "burnout_role": "physician",
      "dob_string": dob,
      "years_in_profession": years_in_profession,
      gender,
      "burnout_location": department,
      "burnout_specialty": specialty,
      code
    })
    .then(function (response) {
      console.log('USER CREATION SUCCESS!',response);
      dispatch(userCreationSuccess(`A confirmation email has been sent to ${email} with a link to set your initial password.`));
      const {confirmation_token} = response.data;
      navigateTo(`/confirm-password/${confirmation_token}/${email.toLowerCase()}`);
    })
    .catch(function (error) {
      console.error("USER CREATION FAILURE!", error.response);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data.errors.email) {
          dispatch(userCreationFailure("An account has already been setup with that email"));
          navigateTo(`/login?email=${email}`);
        } else {
          console.log(error.response);
          dispatch(userCreationFailure(error.response.data.errors))
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        dispatch(userCreationFailure(error.message));
      } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(userCreationFailure(error.message));
      }
    })
    .finally(function () {
      dispatch(creatingUser(false));
    });
  }
}

// this function is for creating a user that is also being billed
export function bill_user(code,
                          card_number,
                          expiration_year,
                          expiration_month,
                          card_code,
                          first_name,
                          last_name,
                          email,
                          specialty,
                          gender,
                          dob,
                          license_type,
                          organization,
                          practice_id,
                          block_id=null) {
  return (dispatch, getState)=> {
    if (getState().authentication.requestPending) {
      return;
    }
    dispatch(creatingUser(true));

    axios.post(`${process.env.REACT_APP_API_URL}/api/register`, {
      "enabled": true,
      "practice_ids": [practice_id],
      "is_provider": true,
      "ce_eligible": true,
      "provider_title": license_type,
      first_name,
      last_name,
      "email": email,
      "burnout_role": "physician",
      "dob_string": dob,
      gender,
      "burnout_location": organization,
      "burnout_specialty": specialty,
      block_id,
      code,
      card_number,
      expiration_year,
      expiration_month,
      card_code
    },{
      headers: {
        // uid: credentials.email,
        // "access-token": credentials.accessToken,
        // client: credentials.client
      }
    })
      .then(function (response) {
        console.log('USER BILLING SUCCESS!',response);
        dispatch(userCreationSuccess(`A confirmation email has been sent to ${email} with a link to set your initial password.`));
        const {confirmation_token} = response.data;
        navigateTo(`/confirm-password/${confirmation_token}/${email.toLowerCase()}`);
      })
      .catch(function (error) {
        console.error("USER BILLING FAILURE!", error.response);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.data.errors.email) {
            dispatch(userCreationFailure("An account has already been setup with that email"));
            navigateTo(`/login?email=${email}`);
          } else {
            console.log(error.response);
            dispatch(userCreationFailure(error.response.data.errors))
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          dispatch(userCreationFailure(error.message));
        } else {
          // Something happened in setting up the request that triggered an Error
          dispatch(userCreationFailure(error.message));
        }
      })
      .finally(function () {
        dispatch(creatingUser(false));
      });
  }
}

export function get_user(credentials,id,redirect=null) {
  return (dispatch, getState)=> {
    if (getState().authentication.requestPending) {
      return;
    }
    dispatch(gettingUser(true));

    axios.get(`${process.env.REACT_APP_API_URL}/api/users/${id}`,{
      headers: {
        uid: credentials.email,
        "access-token": credentials.accessToken,
        client: credentials.client
      }
    })
    .then(function (response) {
      console.log('GETTING USER DATA SUCCESS!',response);
      dispatch(userGetSuccess(response));
      localStorage.setItem('currentSession',JSON.stringify({
        // TODO do not store user info in localStorage. reacquire it from rails
        user: response.data,
        ...credentials
      }));
      navigateTo(redirect ? redirect : `/enrichment_center`);
    })
    .catch(function (error) {
      console.log("GETTING USER DATA FAILURE!");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        console.log("ERROR WITH RESPONSE", error.response);
        if (error.response.data.errors && Array.isArray(error.response.data.errors) && (error.response.data.errors.includes("Authorized users only.") ||
            error.response.data.errors.includes("You need to sign in or sign up before continuing."))){
          dispatch(logout("Your session has timed out, please log back in."))
        } else {
          dispatch(userGetFailure(error.response.data.errors));
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("ERROR WITHOUT RESPONSE", error.request);
        dispatch(userGetFailure(error.message));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("didn't even setup request for Results Data", error.message);
        dispatch(userGetFailure(error.message));
      }
    })
    .finally(function () {
      dispatch(validatingToken(false));
    });
  }
}


export function check_session_timeout(credentials) {
  return dispatch => {
    console.log('checking session timeout');
    axios.get(`${process.env.REACT_APP_API_URL}/api/check_session_timeout`, {
      headers: {
        uid: credentials.email,
        "access-token": credentials.accessToken,
        client: credentials.client
      }
    })
    .then(function(response) {
      // the check_session_timeout returns true if the users session has expired
      if(response.data) {
        dispatch(logout("Your session has timed out due to inactivity, please log back in."));
      } else {
        console.log('session still valid');
      }
    })
    .catch(function (error) {
      console.error("ERROR CHECKING SESSION");
      console.log(error.message);
      // if session is no longer valid, the server will actually respond with a status of 404
      dispatch(logout("Your session has timed out due to inactivity, please log back in."));
    })
  }
}

export function reset_session_timeout(credentials) {
  return dispatch => {
    // console.log('refreshing session timeout');
    axios.get(`${process.env.REACT_APP_API_URL}/api/reset_user_clock`, {
      headers: {
        uid: credentials.email,
        "access-token": credentials.accessToken,
        client: credentials.client
      }
    })
    .then(function(response) {
      // console.log('refreshed user session');
    })
    .catch(function (error) {
      console.error("ERROR CHECKING SESSION");
      console.log(error);
      dispatch(logout(error.message));
    })
  }
}

export const update_access_token = (accessToken, client, email, expiry) => ({type: UPDATE_ACCESS_TOKEN, accessToken, client, email, expiry});