import {
  SURVEY_FETCH_FAILURE,
  SURVEY_FETCH_SUCCESS,
  FETCHING_SURVEY,
  SUBMITTING_SURVEY,
  SURVEY_SUBMIT_SUCCESS,
  SURVEY_SUBMIT_FAILURE,
  PULSE_CHECK_SURVEY_FETCH_FAILURE,
  PULSE_CHECK_SURVEY_FETCH_SUCCESS,
  FETCHING_PULSE_CHECK_SURVEY,
  SUBMITTING_PULSE_CHECK_SURVEY_RESPONSE,
  PULSE_CHECK_SURVEY_RESPONSE_FAILURE
} from "../_actions/_types.actions";

const initialState = {
  survey_questions: [],
  id: 0,
  fetchingSurvey: false,
  surveyFetchError: null,
  submittingSurvey: false,
  surveySubmitError: null,

  pulseCheckSurvey: {},
  fetchingPulseCheckSurvey: false,
  pulseCheckSurveyError: null,

  submittingPulseCheckSurvey: false,
  pulseCheckSurveySubmitError: null,
};

export default function survey(state = initialState, action) {
  switch (action.type) {
    case FETCHING_SURVEY:
      return Object.assign({}, state,{fetchingSurvey: action.status});
    case SURVEY_FETCH_SUCCESS:
      return Object.assign({}, state, {...action.data, surveyFetchError: null});
    case SURVEY_FETCH_FAILURE:
      return Object.assign({}, state, {surveyFetchError: action.error});
    case SUBMITTING_SURVEY:
      return Object.assign({}, state, {submittingSurvey: action.status});
    case SURVEY_SUBMIT_SUCCESS:
      return Object.assign({}, state, {surveySubmitError: null});
    case SURVEY_SUBMIT_FAILURE:
      return Object.assign({}, state, {surveySubmitError: action.error});
    case PULSE_CHECK_SURVEY_FETCH_FAILURE:
      return Object.assign({}, state, {pulseCheckSurveyError: action.error});
    case PULSE_CHECK_SURVEY_FETCH_SUCCESS:
      return Object.assign({}, state, {pulseCheckSurvey: action.data});
    case FETCHING_PULSE_CHECK_SURVEY:
      return Object.assign({}, state, {fetchingPulseCheckSurvey: action.status});
    case PULSE_CHECK_SURVEY_RESPONSE_FAILURE:
      return Object.assign({}, state, {pulseCheckSurveySubmitError: action.error});
    case SUBMITTING_PULSE_CHECK_SURVEY_RESPONSE:
      return Object.assign({}, state, {submittingPulseCheckSurvey: action.status});
    default:
      return state;
  }
}

/*
Global Variables that are based off the state tree's structure
 */
const questionSort = (q1,q2) => (q1.display_order-q2.display_order);

export const getAllQuestions = (survey) => survey.survey_questions.map(q => {
  const {id, question, type, instructions, short_question, question_num, category, choices, display_options, question_group} = q.question;
  return {id, question, type, instructions, short_question, question_num, category, choices, display_options, display_order: q.display_order, question_group};
}).sort(questionSort);

// we only care about multiple choice questions that are marked to be shown on the charts'
// these questions are marked as such using question.display_options.show_on_chart
export const getMultipleChoiceQuestionsForCharts = (survey) => {
  const questions = getAllQuestions(survey);
  return questions.filter(q => q.type === "MultipleChoiceQuestion" && q.display_options?.show_on_chart === true);
};

export const getChoices = (survey, question) => {
  if (question && question.hasOwnProperty("choices")) {
    return question.choices
  } else return [];
};

export function getBurnoutQuestion(survey) {
  const questions = getAllQuestions(survey);
  return questions.find(q => q.short_question === process.env.REACT_APP_BURNOUT_SHORT_QUESTION);
}

export function getAgreeDisagreeQuestions(survey) {
  const questions = getMultipleChoiceQuestionsForCharts(survey);
  return questions.filter(q => q.type === "MultipleChoiceQuestion"  && q.short_question !== process.env.REACT_APP_BURNOUT_SHORT_QUESTION);
}

export function getFreeTextQuestionsForCharts(survey) {
  const questions = getAllQuestions(survey);
  return questions.filter(q => q.type === "FreeTextQuestion" && q.display_options?.show_on_chart === true);
}

export function getDemographicQuestions(survey) {
  const questions = getAllQuestions(survey);
  return questions.filter(q => (q.category?.id === parseInt(process.env.REACT_APP_DEMOGRAPHIC_QUESTION_CATEGORY_ID) || q.category?.name === 'Demographic'));
}