import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './_reducers';
import logger from './redux_logger';

export default function configureAppStore(preloadedState = undefined) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
      }).concat(...(process.env.NODE_ENV === 'development' ? [logger] : [])),
    preloadedState,
    devTools: process.env.NODE_ENV === 'development',
  });
}