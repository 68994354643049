import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from 'react-redux';
import { Row, Button, OverlayTrigger, Tooltip, Col, Modal, Spinner, Container } from "react-bootstrap";
import styles from "./external_video_page.module.scss";
import { FiPlayCircle } from "react-icons/fi";
import { FaRegFilePdf, FaUserGraduate, FaFileDownload } from "react-icons/fa";
import videoIcon from "../../../../images/video_icon.svg"
import audioIcon from "../../../../images/audio_icon.svg";
import fileIcon from "../../../../images/file_icon.svg";
import seLogo from "../../../../images/logo/SEHQC-Logo-Horizontal-Color-White.svg"
import RecommendedReading from "../VideoPage/RecommendedReading";
import LearningObjectives from "../VideoPage/LearningObjectives";
import References from "../VideoPage/References";

//TODO: instead of generating the HTML content here, we should do it server-side so that the metaData (thumbnail img, title) can be scraped by social media sites

const ExternalVideoPage = (props) => {
  const {
    timeWatched,
    record_id,
    claimedContent,
    demoUser
  } = props;
  // eslint-disable-next-line
  const [mediaFinished, setMediaFinished] = useState(false);
  const [watchedTime, setWatchedTime] = useState(timeWatched?.[record_id]?.["seconds_watched"] ? timeWatched[record_id]["seconds_watched"] : 0);
  const [currentTime, setCurrentTime] = useState(0);
  const [lastUpdated, setLastUpdated] = useState('currentTime');
  const [lastTimeLogged, setLastTimeLogged] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false)
  const [main, setMain] = useState({})
  const [handout, setHandout] = useState({})
  const [error, setError] = useState(null)

  const LoadingModal = ({ show, onHide }) => {
    return (
    
      <Modal
      show={!dataLoaded}
      size={"sm"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => null}
      className={styles.modal}
    >
      <Modal.Header className={styles.modalTitle}>
        <Modal.Title id="contained-modal-title-vcenter">
          Loading Video Page
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Container className={styles.spinnerContainer}>
          <Spinner animation="grow" role="status" aria-hidden="true" className={styles.spinner}/>
        </Container>
      </Modal.Body>
    </Modal>
     
    )
  }

  const ErrorModal = ({ show, onHide }) => {
    return (
    
      <Modal
      show={!dataLoaded}
      size={"sm"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => null}
      className={styles.modal}
    >
      <Modal.Header className={styles.modalTitle}>
        <Modal.Title id="contained-modal-title-vcenter">
          Error
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Container className={styles.spinnerContainer}>
          <p>{error}</p>
        </Container>
      </Modal.Body>
    </Modal>
     
    )
  }

  const displayImages = () => {
    if (main.type === "Video") {
      document.getElementById("videoImages").style.display = "block";
    } else if (main.type === "Audio") {
      document.getElementById("mediaIcon").style.display = "block";
    }
  };

  const playMedia = () => {
    if (main.type === "Video") {
      document.getElementById("videoImages").style.display = "none";
      document.getElementById("video").play();
    } else if (main.type === "Audio") {
      document.getElementById("mediaIcon").style.display = "none";
      document.getElementById("audio").play();
    }
  };

  const returnIcon = type => {
    switch (type) {
      case "Video":
        return `${videoIcon}`;
      case "Audio":
        return `${audioIcon}`;
      case "PDF":
        return `${fileIcon}`;
      default:
        return `${videoIcon}`;
    }
  };

  const returnClass = type => {
    switch (type) {
      case "Video":
        return `${styles.videoTypeIcon}`;
      case "Audio":
        return `${styles.audioTypeIcon}`;
      case "PDF":
        return `${styles.pdfTypeIcon}`;
      default:
        return `${styles.videoTypeIcon}`;
    }
  };

  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const encryptedString = urlParams.get('data');

    const url = `${process.env.REACT_APP_API_URL}/api/enrichment_center_external_video_page/${encryptedString}`;

    axios.get(url)
      .then((response) => {
        // Handle successful response
        setMain(response.data.main)
        setHandout(response.data.handout)
        setDataLoaded(true)

      })
      .catch((error) => {
        // Handle error
        setError('Error fetching data.')
      })
      .finally(() => {
        // Code to run regardless of success or failure
        console.log('Request completed');
      });
  }, []);

  // eslint-disable-next-line
  Date.prototype.toDateInputValue = (function () {
    let local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  });


  if (timeWatched?.[record_id]?.["seconds_watched"] && timeWatched[record_id]["seconds_watched"] > watchedTime) {
    setWatchedTime(timeWatched[record_id]["seconds_watched"]);
  }

  const latestClaimedCME = Array.isArray(claimedContent) && claimedContent.filter(c => c.lesson_number === main.lesson_number).sort((a, b) => (new Date(b.created_at).getTime()) - (new Date(a.created_at).getTime()))[0];

  const file_url = main.file_url;
  const subtitle_url = main.subtitle_file_url

  // Work around to allow multiple references per video using a single field in quickbase. references and
  // their respective urls should be seperated using | character in quickbase

  const mainVideoRef = useRef(null);
  const mainAudioRef = useRef(null);

  const handleVideoClick = () => {
    // Toggle play/pause
    if (mainVideoRef.current.paused) {
      mainVideoRef.current.play();
    } else {
      mainVideoRef.current.pause();
    }
  };

  const handleAudioClick = () => {
    // Toggle play/pause
    if (mainAudioRef.current.paused) {
      mainAudioRef.current.play();
    } else {
      mainAudioRef.current.pause();
    }
  }

  const filterParams = {
    redirect_url: `/enrichment_center/video/${main?.record_id_}`,
    time_watched: watchedTime
  };
  const queryString = new URLSearchParams(filterParams).toString();

  return (
    <>
      <div className={styles.seLogo}>
        <img src={seLogo} alt="SE Healthcare" />
      </div>

      {!dataLoaded && !error && <LoadingModal/>}

      {!dataLoaded && error && <ErrorModal/>}



      {dataLoaded &&
        <>
          <Row className={styles.headerRow}>
            {/* video header */}
            <Col className={styles.videoHeader} lg={8}>

              <h1>{main.title}</h1>
              <div
                className={styles.videoContainer}
                onClick={playMedia}
              >
                <div
                  className={
                    main.type === "Video"
                      ? `${styles.videoImages}`
                      : `${styles.audioImages}`
                  }
                  id="videoImages"
                >
                  <span className={styles.videoIcon} id="mediaIcon">
                    <FiPlayCircle />
                    {main.type === "Video" ? <span>Watch video</span> : null}
                    {main.type === "Audio" ? <span>Listen Now</span> : null}
                  </span>

                  <img className={styles.videoImg} src={main.image} alt="" />
                  <div className={styles.imageOverlay} />
                </div>
                {/* end videoImages */}
                {main.type === "Video" ? (
                  <video
                    id={"video"}
                    controls
                    controlsList="nodownload"
                    onPlaying={(e) => {
                      const video = e.currentTarget;
                      if (Math.floor(video.currentTime) === 0) {

                      }
                    }}
                    onClick={handleVideoClick}

                    onEnded={(e) => {
                      setMediaFinished(true);
                      displayImages();
                    }
                    }
                    onTimeUpdate={(e) => {
                      const video = e.currentTarget;

                      if (!video.seeking) {
                        if (video.currentTime > watchedTime) {
                          setWatchedTime(video.currentTime);
                          setLastUpdated('watchedTime');
                        }
                        //tracking time updated  after user rewinds
                        else {
                          setCurrentTime(video.currentTime)
                          setLastUpdated('currentTime');
                        }
                        if (video.currentTime > lastTimeLogged + 10) {
                          // lets only update the redux store timeWatched when we log to the backend
                          // this will cutback on excessive updates to the redux store
                          setLastTimeLogged(video.currentTime);

                        }
                      }
                    }}
                    onSeeking={(e) => {
                      const video = e.currentTarget;
                      // guard against infinite recursion:
                      // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
                      const delta = video.currentTime - watchedTime;
                      if (delta > 0) {
                        video.pause();
                        //play back from where the user started seeking after rewind or without rewind
                        if (lastUpdated === 'currentTime') {
                          video.currentTime = currentTime;
                        } else if (lastUpdated === 'watchedTime') {
                          video.currentTime = watchedTime
                        }
                        video.play();
                      }
                    }}
                    ref={mainVideoRef}
                    className={styles.video}
                    key={file_url}
                    crossOrigin="anonymous"
                  >
                    <source src={file_url} type="video/mp4" />
                    {subtitle_url && <track
                      label="English"
                      kind="subtitles"
                      srcLang="en"
                      src={subtitle_url}
                      default />}
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <div className={styles.videoPlaceholder} />
                )}
              </div>
              {/* {/* end video container */}
              {main.type === "Audio" ? (
                <audio
                  id={"audio"}
                  controls
                  controlsList="nodownload"
                  ref={mainAudioRef}
                  onPlaying={(e) => {
                    if (Math.floor(e.currentTarget.currentTime) === 0) {
                      //   logEvent("audio started", main.record_id_, 0)
                    }
                  }}
                  onTimeUpdate={(e) => {

                  }}
                  onEnded={() => {
                    setMediaFinished(true);
                    displayImages();
                    // logEvent("audio ended", main.record_id_);
                  }}
                  onClick={handleAudioClick}
                  src={file_url}
                />
              ) : null}
              <h6>{main.tagline}</h6>
              <p>{main.description}</p>
              <div className={styles.videoInfo}>
                <span className={returnClass(main.type)}>
                  <img src={returnIcon(main.type)} alt="" />
                </span>
                <span className={styles.videoType}>
                  Type: {` ${main.type}`}
                </span>{" "}
                |
                <span className={styles.videoLesson}>
                  Lesson: {main.lesson_number}
                </span>
                {main.length ? (
                  <span className={styles.videoDuration}>
                    <span className={styles.separator}>|</span>
                    Duration:
                    <span className={styles.time}>{main.length}</span>
                  </span>
                ) : null}
              </div>
            </Col>
            {/* end video header */}
            <Col className={styles.recommendedLearning} lg={4}>

              {// Handout Download Button
                handout && handout != null &&
                (
                  <Button
                    href={handout.file_url ? handout.file_url : `${process.env.REACT_APP_S3_URL}/documents/burnout-handout.pdf`}
                    block target="_blank" rel="noopener noreferrer" variant={"info"} className={styles.sideButton}
                  // onClick={() => logEvent("clicked handout", handout.title)}
                  >
                    <FaRegFilePdf />
                    <span>
                      {`${handout.title}  Handout `}
                    </span>
                  </Button>
                )
              }

              {
                (main.cme === "1" || main.contact_hours === "1") && (
                  <div className={styles.cmeCredit}>
                    <OverlayTrigger overlay={<Tooltip
                      id="tooltip-disabled">{"Please login at the bottom of this page to claim your CE credit."}</Tooltip>}>
                      <div>
                        <Button variant={"info"} style={{ pointerEvents: 'none' }} className={styles.claimButton} block
                          disabled>
                          <FaUserGraduate /> Claim Credit
                        </Button>
                      </div>
                    </OverlayTrigger>

                    {
                      latestClaimedCME && (
                        <Button variant={"info"} href="https://reflectce.com/claim" target={'_blank'}
                          className={styles.sideButton} block disabled={demoUser}>
                          <FaFileDownload /> View Certificate
                        </Button>
                      )
                    }

                    <p>
                      This experience is powered by CMEfy - an AI-powered platform that directs learners along a pathway to
                      capture reflections at the point of inspiration, point of care. Clinicians may earn CME/CE credit via
                      ReflectCE, the accredited activity portal. Learn more at <a
                        href={"https://info.cmefy.com/"}>info.cmefy.com/</a>
                    </p>
                    <p>
                      There are no conflicts of interest with this activity.
                    </p>
                  </div>
                )
              }
              <LearningObjectives main={main} />
              <RecommendedReading main={main} />
              <References main={main} />
            </Col>
            {/* end recommended learning */}
          </Row>
          {/* <Row className={styles.footerDescRow}>
            <h2>
              Header
            </h2>
            <p style={{ padding: 20 }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras lorem libero, lobortis at erat ut, interdum tempor arcu. Nunc commodo magna vitae lacus ultrices sollicitudin. Phasellus mattis risus a velit volutpat, vel dictum sem placerat. Sed vel facilisis libero. Proin arcu massa, egestas in sapien sed, posuere finibus diam. Sed vel pulvinar felis. Vestibulum nibh nisl, laoreet eget pretium eget, elementum eget felis. Praesent eget neque tempor, facilisis leo in, accumsan purus. Donec volutpat tortor vel interdum finibus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis gravida lectus volutpat justo dapibus porta. Morbi condimentum sit amet purus dignissim molestie.
            </p>

          </Row> */}
          <Row className={styles.footerButtonRow}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <p className={styles.footerTxt}>Already have an account?</p>
              <Button className={styles.footerButton} href={`${process.env.REACT_APP_BASE_URL}/login/?${queryString}`}>
                Login
              </Button>

            </div>

            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <p className={styles.footerTxt}>Interested? Click here to find out more.</p>
              <Button className={styles.footerButton} href="https://www.sehealthcaresolutions.com/">Learn More</Button>
            </div>
          </Row>
        </>
      }


    </>
  );
}

ExternalVideoPage.defaultProps = {
  claimedContent: [],
  claimedContactHourContent: [],
  section: "0",
  timeWatched: {}
}

const mapStateToProps = (state, ownProps) => {
  // const { record_id } = ownProps;
  const { timeWatched, content, categories } = state.enrichment_center
  // const { enrichment_center } = state

  return {
    // this selector function gives you { main, handouts, deeper, wider }
    watchedVideos: state.feedback.watchedVideos,
    timeWatched,
    content,
    categories
  }
};

export default connect(mapStateToProps)(ExternalVideoPage);